import { GetLocalizations } from '@client-shopify/gql/storefront/api/queries';
import { useQuery } from '@tanstack/react-query';
import { DownChevronIcon } from '@ui/components/core';
import { Button } from '@ui/components/core/button';
import Spinner from '@ui/components/core/spinner';
import env from '@ui/env';
import { toast } from '@ui/hooks/use-toast';
import { useCartBuyerUpdate } from '@ui/hooks/useCartMutations';
import { useCookie } from '@ui/hooks/useCookie';
import { useCartStore } from '@ui/store.export';
import cn from '@ui/utils/cn';
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/components/core/dropdownMenu';

const LocalCurrencyDropdown = ({ className,disablePreText = false }: { className?: string, disablePreText?: boolean }) => {
  const { data: localizations } = useQuery({
    queryKey: ['localizations'],
    queryFn: async () => (await GetLocalizations()).data,
  });

  const availableCountries = useMemo(() => localizations?.localization.availableCountries || [], [localizations]);
  const [selectedCountry, setSelectedCountry] = React.useState<string>(env.NEXT_PUBLIC_REGION);
  const selectedCurrency = availableCountries.find((country) => country.isoCode === selectedCountry)?.currency;

  useEffect(() => {
    const customerCountry = localStorage.getItem('customerCountry');
    if (customerCountry && availableCountries.length > 0) {
      if (!availableCountries.find((country) => country.isoCode === customerCountry))
        return localStorage.removeItem('customerCountry');
      setSelectedCountry(customerCountry);
    }
  }, [availableCountries]);

  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const { CartBuyerIdentityUpdateMutation } = useCartBuyerUpdate();
  const cartId = useCartStore((state) => state?.cartId);
  const buyerIp = useCookie('shopifyBuyerIp');

  // Remove the `country` search parameter from the URL when reloading the current page.
  // This param acts as an override and takes precedence over what is stored in local storage.
  // If the site visitor picked a different currency, then the country override is unnecessary.

  const reload = () => {
    const url = new URL(router.asPath, env.NEXT_PUBLIC_BASE_URL);
    url.searchParams.delete('country');
    window.location.href = url.toString();
  };

  const handleClick = async (country: (typeof availableCountries)[0]) => {
    if (!cartId) {
      setSelectedCountry(country.isoCode);
      localStorage.setItem('customerCountry', country.isoCode);
      reload();
      return;
    }

    setLoading(true);

    try {
      await CartBuyerIdentityUpdateMutation({
        cartId,
        buyerIP: buyerIp,
        buyerIdentity: {
          countryCode: country.isoCode,
        },
      });

      setSelectedCountry(country.isoCode);
      localStorage.setItem('customerCountry', country.isoCode);
      reload();
    } catch {
      toast({
        description: 'Failed to Set the Currency.',
        variant: 'destructive',
      })
    }

    setLoading(false);
  };

  return (
    <div className={`shrink-0 flex items-center ${className}`}>
      {!disablePreText && <p className="text-xs hidden mr-2 md:block">YOU&apos;RE SHOPPING IN:</p>}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant='unstyled'
            className={cn(
              'flex items-center justify-center no-underline scrollbar-none',
              'text-[12px] font-normal space-x-1 group',
              'focus:outline-none w-[57px] min-h-6',
            )}
          >
            {loading || !selectedCurrency ? (
              <Spinner className="size-5 border-[3px]" />
            ) : (
              <>
                <span>{`${selectedCurrency.symbol}${selectedCurrency.isoCode}`}</span>
                <DownChevronIcon className="group-data-[open]:rotate-180 transition" width={16} height={16} />
              </>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={8} className="z-[1002] max-h-[400px] rounded-none border border-black">
          {sortBy(availableCountries, 'name').map((country) => (
            <DropdownMenuItem
              key={country.isoCode}
              data-selected={selectedCountry === country.isoCode || undefined}
              onClick={() => handleClick(country)}
              className="text-xs py-1 data-[selected]:underline hover:underline underline-offset-2"
            >
              {country.name} ({country.currency.isoCode} {country.currency.symbol})
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default LocalCurrencyDropdown;
